<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <ToggleHeader v-bind="{ title, visible }" @toggle="toggle">
    <transition
      enter-active-class="animated faster fadeIn"
      leave-active-class="animated faster fadeOut"
      mode="out-in"
    >
      <div v-if="visible">
        <hr class="mt-0 has-background-dark" />
        <div class="columns is-multiline is-vcentered is-mobile mb-6">
          <div v-if="listing.myStock" class="column is-12">
            <MyStock />
          </div>
          <div v-if="listing.myStock && pricePosition" class="column is-12">
            <PricePosition v-bind="{ pricePosition, isSold: listing.sold }" />
          </div>
          <div class="column is-12">
            <div>
              <hr
                v-if="listing.myStock && pricePosition"
                class="mt-0 has-background-soft"
              />
              <div class="columns is-multiline is-mobile">
                <div class="column is-12">
                  <p class="title is-6 has-text-dark">
                    <span class="is-valigned">Advert details</span>
                  </p>
                </div>
                <div class="column is-12" v-if="listing.listingPrice">
                  <div class="columns is-mobile">
                    <div class="column">
                      <p class="has-text-weight-light">
                        {{ priceText }}
                      </p>
                    </div>
                    <div class="column is-narrow">
                      <p class="has-text-weight-semibold">
                        <span>{{ pounds(listing.listingPrice) }} </span>
                        <span v-if="excVat" class="is-size-7">
                          subject to VAT status
                          <i
                            class="ml-1 fal has-text-info fa-info-circle is-size-6 is-clickable"
                            :title="vatTooltip"
                            v-tippy="{ placement: 'right' }"
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="priceChange" class="column is-12">
                  <div class="columns is-mobile">
                    <div class="column">
                      <p class="has-text-weight-light">
                        Original listing price
                      </p>
                    </div>
                    <div class="column is-narrow">
                      <p class="has-text-weight-semibold">
                        <span>
                          {{ pounds(listing.listingOriginalPrice) }}
                        </span>
                        <span v-if="excVat" class="is-size-7">
                          subject to VAT status
                          <i
                            class="ml-1 fal has-text-info fa-info-circle is-size-6 is-clickable"
                            :title="vatTooltip"
                            v-tippy="{ placement: 'right' }"
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-if="listing.myStock && pricePosition"
                  class="column is-12"
                >
                  <div class="columns is-mobile">
                    <div class="column">
                      <p class="has-text-weight-light">
                        <span>Market pricing position</span>
                        <a class="icon is-small has-text-info">
                          <i
                            class="fal fa-info-circle"
                            v-tippy="{ placement: 'right' }"
                            title="Listing price as a percentage of Cazana Retail value"
                          />
                        </a>
                      </p>
                    </div>
                    <div class="column is-narrow">
                      <p class="has-text-weight-semibold">
                        <span
                          :class="`has-text-${pricePosition.colour}`"
                          class="icon"
                        >
                          <i class="fad fa-circle" />
                        </span>
                        <span>{{ pricePosition.rounded }}%</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="column is-12" v-if="daysOnSale">
                  <div class="columns is-mobile">
                    <div class="column">
                      <p class="has-text-weight-light">Days on sale</p>
                    </div>
                    <div class="column is-narrow">
                      <p class="has-text-weight-semibold">{{ daysOnSale }}</p>
                    </div>
                  </div>
                </div>
                <div class="column is-12" v-if="listing.firstSeen">
                  <div class="columns is-mobile">
                    <div class="column">
                      <p class="has-text-weight-light">Date listed</p>
                    </div>
                    <div class="column is-narrow">
                      <p class="has-text-weight-semibold">{{ dateListed }}</p>
                    </div>
                  </div>
                </div>
                <div class="column is-12" v-if="listing.mileage">
                  <div class="columns is-mobile">
                    <div class="column">
                      <p class="has-text-weight-light">Mileage</p>
                    </div>
                    <div class="column is-narrow">
                      <p class="has-text-weight-semibold">
                        {{ number(listing.mileage) }} miles
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12" v-if="showDealerSection">
            <hr class="mt-0 has-background-soft" />
            <div class="columns is-multiline is-mobile">
              <div class="column is-12">
                <p class="title is-6 has-text-dark">
                  <span class="is-valigned">Seller details</span>
                </p>
              </div>
              <div v-if="hasDealer" class="column is-12">
                <div class="columns is-mobile">
                  <div class="column">
                    <p class="has-text-weight-light">Seller</p>
                  </div>
                  <div class="column is-narrow">
                    <p class="has-text-weight-semibold">
                      <span v-if="listing.dealerName">
                        {{ truncate(listing.dealerName) }}
                        <a
                          v-if="truncateDealerName"
                          class="has-text-dark"
                          v-tippy="{ placement: 'right' }"
                          :title="listing.dealerName"
                        >...</a>
                      </span>
                      <span v-else>Privately listed</span>
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="hasLocation" class="column is-12">
                <div class="columns is-mobile">
                  <div class="column">
                    <p class="has-text-weight-light">Location</p>
                  </div>
                  <div class="column is-narrow">
                    <p class="has-text-weight-semibold">
                      {{ listing.dealerLocation }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!listing.sold && lastOnSale" class="column is-12">
            <hr class="mt-0 has-background-soft" />
            <div class="columns is-mobile">
              <div class="column">
                <p class="has-text-weight-light">Last on sale</p>
              </div>
              <div class="column is-narrow">
                <p class="has-text-weight-semibold">{{ lastOnSale }}</p>
              </div>
            </div>
          </div>
          <div v-if="listing.adText" class="column is-12">
            <hr class="mt-0 has-background-soft" />
            <ToggleHeader
              title="Ad text"
              :visible="adTextVisible"
              @toggle="toggleAdText"
            >
              <transition
                enter-active-class="animated faster fadeIn"
                leave-active-class="animated faster fadeOut"
                mode="out-in"
              >
                <p class="has-text-weight-light" v-if="adTextVisible">
                  {{ listing.adText }}
                </p>
              </transition>
            </ToggleHeader>
          </div>
        </div>
      </div>
    </transition>
  </ToggleHeader>
</template>

<script>
import { formatMoney, formatNumber } from 'accounting'
import { formatDate, timeAgo } from '@/utils/date.utils.ts'
export default {
  name: 'CompanionListingModule',
  components: {
    ToggleHeader: () => import('core/components/ToggleHeader'),
    MyStock: () => import('./current-listing/CompanionMyStock'),
    PricePosition: () => import('./CompanionPricePosition')
  },
  data: () => ({
    visible: true,
    adTextVisible: false,
    vatTooltip:
      'This price does not include VAT. Its eligibility for VAT qualification depends on specific criteria, which can vary based on factors such as usage and registration.'
  }),
  props: {
    listing: {
      type: Object,
      required: true
    },
    pricePosition: {
      type: Object,
      required: false,
      default: () => null
    },
    type: {
      type: String,
      required: false,
      default: () => null
    }
  },
  methods: {
    toggle() {
      const action = this.visible ? 'close' : 'open'
      this.$mxp.track(`companion_${action}_current_listing_collapsible`)
      this.visible = !this.visible
    },
    toggleAdText() {
      const action = this.adTextVisible ? 'close' : 'open'
      this.$mxp.track(`companion_${action}_current_listing_adtext`)
      this.adTextVisible = !this.adTextVisible
    },
    pounds(value) {
      return formatMoney(value, '£', 0)
    },
    number(value) {
      return formatNumber(value)
    },
    truncate(value) {
      const end = value.length === 30 ? 30 : 29
      return value.slice(0, end)
    }
  },
  computed: {
    showDealerSection() {
      return this.hasDealer || this.hasLocation
    },
    hasDealer() {
      return this.listing.dealerName || this.isPrivate
    },
    isPrivate() {
      return !this.listing.dealerId
    },
    hasLocation() {
      return this.listing.dealerLocation
    },
    daysOnSale() {
      const { sold, daysOnSale } = this.listing

      if (sold) return daysOnSale <= 1 ? '1 day' : `${daysOnSale} days`
      else {
        return daysOnSale === 0
          ? 'Listed today'
          : daysOnSale === 1
          ? 'Listed yesterday'
          : `${daysOnSale} days`
      }
    },
    lastOnSale() {
      const { lastOnSale } = this.listing
      return lastOnSale ? timeAgo(lastOnSale) : null
    },
    title() {
      const { sold, soldDate } = this.listing
      if (!sold) return 'Currently for sale'

      const date = soldDate ? timeAgo(soldDate) : null
      return soldDate ? `Last for sale ${date}` : 'Previously on sale'
    },
    priceText() {
      return this.listing.sold ? 'Last price' : 'Price'
    },
    priceChange() {
      const { listingOriginalPrice, listingPrice } = this.listing
      return listingOriginalPrice && listingOriginalPrice !== listingPrice
    },
    excVat() {
      return this.type === 'LCV' || this.type === 'HCV'
    },
    dateListed() {
      return formatDate(this.listing.firstSeen)
    },
    truncateDealerName() {
      const { dealerName } = this.listing
      return dealerName && dealerName.length >= 29 && !(dealerName.length <= 30)
    }
  }
}
</script>
